// using ES6 modules
import VanillaSiteSpeedBooster from '@nitsantechnologies/vanilla-site-speed-booster';

// using CommonJS modules
const VanillaSiteSpeed = new VanillaSiteSpeedBooster({
  enableProgressBar: false,
  idBundleJs: 'pageAjax',
  errorMsg: 'Oops! Fatal error in VanillaSiteSpeedBooster plugin',
  mainClassName: '.site-main',
  pageBackForwardReload: true,
  removeUsingTargetClass: ['-1'],
  removeWithoutReloadUsingTargetClass: ['download-teaser__link', 'has-sub-link'],
  langSwitch: '.lang-menu-item',
});
