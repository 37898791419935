//
// Counter JS
//

const counter = document.querySelectorAll('.counter-teaser__float');
if (counter) {
  const floatCommas = (n) => {
    const parts = n.toString().split('.');
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  counter.forEach((item) => {
    const $item = item;
    $item.counterAlreadyFired = false;
    $item.counterSpeed = $item.getAttribute('data-counter-time') / 45;
    $item.counterTarget = +$item.innerText;
    $item.counterCount = 0;
    $item.counterStep = $item.counterTarget / $item.counterSpeed;
    $item.updateCounter = () => {
      $item.counterCount += $item.counterStep;
      $item.innerText = Math.ceil($item.counterCount);

      if ($item.counterCount < $item.counterTarget) {
        setTimeout($item.updateCounter, $item.counterSpeed);
      } else {
        $item.innerText = $item.counterTarget;
      }
      $item.innerText = floatCommas($item.innerText);
    };
  });

  // Function to determine if an element is visible in the web page
  const isElementVisible = function isElementVisible(el) {
    const scroll = window.scrollY || window.pageYOffset;
    const boundsTop = el.getBoundingClientRect().top + scroll;
    const viewport = {
      top: scroll,
      bottom: scroll + window.innerHeight,
    };
    const bounds = {
      top: boundsTop,
      bottom: boundsTop + el.clientHeight,
    };
    return (
      (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom)
      || (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    );
  };

  // Funciton that will get fired uppon scrolling
  const handleScroll = function handleScroll() {
    counter.forEach((item) => {
      const $item = item;

      if ($item.counterAlreadyFired === true) return;
      if (!isElementVisible($item)) return;
      $item.updateCounter();
      $item.counterAlreadyFired = true;
    });
  };

  // Fire the function on scroll
  window.addEventListener('scroll', handleScroll);
}
