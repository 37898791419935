//
// Map JS
//

const mapSvg = document.querySelector('.map__svg-img');

if (mapSvg) {
  const mapSvgPath = mapSvg.querySelectorAll('path');
  const mapSection = mapSvg.closest('.map-section');
  const mapImgBlock = mapSection.querySelectorAll('.map__img-block');
  const mapHeadline = mapSection.querySelectorAll('.map__country-name');

  const removeChildFromSiblings = (elem) => {
    if (!elem.parentNode) {
      return false;
    }

    let sibling = elem.parentNode.firstChild;

    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        sibling.classList.remove('shadowed');
      }
      sibling = sibling.nextElementSibling;
    }
    return true;
  };

  mapSvgPath.forEach(($path) => {
    $path.addEventListener('click', () => {
      const mapId = $path.getAttribute('id');
      removeChildFromSiblings($path);
      $path.classList.add('shadowed');
      mapSection.querySelector('.map__content').classList.add('map__content--slider-hidden');
      const mapContent = mapSection.querySelector(`div[data-content-id='${mapId}']`);
      mapHeadline.forEach(($mapHead) => {
        $mapHead.closest('.headline-block').classList.add('map-country-active');
        $mapHead.querySelector('span').textContent = mapContent.querySelector('h2').textContent;
      });

      mapImgBlock.forEach(($block) => {
        $block.classList.remove('map__img-block--active');
      });

      mapContent.classList.add('map__img-block--active');
    });
  });

  document.addEventListener('click', (event) => {
    if (event.target.tagName.toLowerCase() !== 'path' && event.target !== undefined) {
      mapHeadline.forEach(($mapHead) => {
        $mapHead.closest('.headline-block').classList.remove('map-country-active');
      });
      mapSection.querySelector('.map__content').classList.remove('map__content--slider-hidden');
    }
  });
}
