//
// Scroll To Top
//

const href = window.location.hash;
if (href) {
  const elem = document.querySelector(href);
  if (elem) {
    window.scroll({
      top: elem.offsetTop - 65,
      left: 0,
      behavior: 'smooth',
    });

    setTimeout(() => {
      window.scroll({
        top: elem.offsetTop - 65,
        left: 0,
        behavior: 'smooth',
      });
    }, 1000);
  }
}
