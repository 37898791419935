//
// Tabs Slider JS
//

const tabsSlider = document.querySelectorAll('.tabs-slider');
if (tabsSlider) {
  tabsSlider.forEach(($slider) => {
    const tabsSliderSec = $slider.closest('.tabs-slider-section');
    const tabsSliderSecBottomOffset = tabsSliderSec.getBoundingClientRect().bottom;
    const sliderNav = $slider.querySelector('.carousel-indicators--tabs');
    const sliderNavOffset = sliderNav.getBoundingClientRect().top;

    window.addEventListener('scroll', () => {
      if (window.scrollY > sliderNavOffset) {
        document.body.classList.add('tasb-section--sticky');
      } else {
        document.body.classList.remove('tasb-section--sticky');
      }

      if (window.scrollY > tabsSliderSecBottomOffset) {
        if (document.body.classList.contains('tasb-section--sticky')) {
          document.body.classList.remove('tasb-section--sticky');
        }
      } else if (window.scrollY > sliderNavOffset && window.scrollY < tabsSliderSecBottomOffset) {
        document.body.classList.add('tasb-section--sticky');
      }
    }, false);
  });
}
