//
// Thume Slider
//
import Flickity from 'flickity';

const carousel = document.querySelectorAll('.thumb-slider__blocks');
const carousel2 = document.querySelectorAll('.thumb-slider-indicators');

let flkty;
let flkty2;

if (carousel.length) {
  carousel.forEach((element) => {
    flkty = new Flickity(element, {
      pageDots: false,
      wrapAround: true,
    });
  });
}

if (carousel2.length) {
  carousel2.forEach((element) => {
    const sliderId = element.closest('.thumb-slider').querySelector('.thumb-slider__blocks').getAttribute('id');
    flkty2 = new Flickity(element, {
      contain: true,
      pageDots: false,
      cellAlign: 'center',
      prevNextButtons: false,
      asNavFor: `#${sliderId}`,
    });
  });
}

function equalHeight(resize) {
  const elements = document.getElementsByClassName('thumb-slider-matchheight');

  const allHeights = [];
  let i = 0;
  if (resize === true) {
    for (i = 0; i < elements.length; i++) {
      elements[i].style.height = 'auto';
    }
  }
  for (i = 0; i < elements.length; i++) {
    const elementHeight = elements[i].clientHeight;
    allHeights.push(elementHeight);
  }
  for (i = 0; i < elements.length; i++) {
    elements[i].style.height = `${Math.max.apply(Math, allHeights)}px`;
    if (resize === false) {
      elements[i].className = `${elements[i].className} show`;
    }
  }
}

setTimeout(() => {
  (function () {
    equalHeight(false);
  }());
  window.onresize = function () {
    equalHeight(true);
    if (flkty2) {
      flkty2.reposition();
      flkty2.resize();
    }
  };
}, 500);
