//
// Logowall
//
const btnshow = document.querySelector('.logos-teaser__btn');
const logoitemshow = document.querySelectorAll('.logos-teaser__item');
const btnhide = document.querySelectorAll('.logos-teaser__btn .btn');
if (btnshow) {
  btnshow.addEventListener('click', () => {
    btnhide.forEach((elem) => {
      elem.classList.toggle('btn-hide');
    });
    logoitemshow.forEach((elem) => {
      elem.classList.toggle('logos-item-hide');
    });
  });
}
