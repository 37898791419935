//
// Service - section
//
const tabsdata = document.querySelectorAll('.tabs-hide');
const tabsdata1 = document.querySelectorAll('.tabs-hide1');
const tabshide = document.querySelector('.tabs-hide-selectors');
const tabshide1 = document.querySelector('.tabs-hide1-selectors');
const bghide = document.querySelector('.bg-change');
if (tabshide1) {
  tabshide1.addEventListener('click', () => {
    bghide.classList.add('bg-hide');
    tabshide.classList.remove('tab-bg');
    tabshide1.classList.add('tab-bg');

    tabsdata.forEach(($elems) => {
      $elems.classList.remove('tab-visible');
    });

    tabsdata1.forEach(($item) => {
      $item.classList.add('tab-visible');
    });
  });
}

if (tabshide) {
  tabshide.addEventListener('click', () => {
    bghide.classList.remove('bg-hide');
    tabshide1.classList.remove('tab-bg');
    tabshide.classList.add('tab-bg');
    tabsdata.forEach(($elems) => {
      $elems.classList.add('tab-visible');
    });
    tabsdata1.forEach(($item) => {
      $item.classList.remove('tab-visible');
    });
  });
}
