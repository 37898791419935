//
// Form validation JS
//

const forms = document.querySelectorAll('.needs-validation');
if (forms) {
  const isEmpty = (str) => !str.trim().length;

  // Loop over them and prevent submission
  Array.prototype.slice.call(forms).forEach((form) => {
    const requiredFields = form.querySelectorAll('[required]');
    if (requiredFields) {
      requiredFields.forEach(($element) => {
        $element.addEventListener('blur', () => {
          if (!isEmpty($element.value)) {
            $element.closest('.form-group').classList.remove('has-error');
          }
        });
      });
    }

    form.addEventListener('submit', (event) => {
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }

      form.classList.add('was-validated');
      requiredFields.forEach(($ele) => {
        if (isEmpty($ele.value)) {
          $ele.closest('.form-group').classList.add('has-error');
        }
      });
    }, true);
  });
}
