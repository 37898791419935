// //
// // Phone-validation js 
// //

const forms = document.querySelector('.cus-form .submit .btn');
const inputPhone = document.querySelectorAll("[type='tel']");

const isEmpty = (str) => !str.trim().length;
const validReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

const checkPhone = ($val) => {
  if ($val.match(validReg)) {
    return true;
  }
};
if (forms) {
  forms.addEventListener('click', () => {
    if (inputPhone) {
      inputPhone.forEach(($phone) => {
        if (!isEmpty($phone.value)) {
          $phone.closest('.form-group').classList.remove('has-error');
        }
        if (!checkPhone($phone.value)) {
          $phone.closest('.form-group').classList.add('has-error');
        } else {
          $phone.closest('.form-group').classList.remove('has-error');
        }
      });
    }
  });
  if (inputPhone) {
    inputPhone.forEach(($phone) => {
      $phone.addEventListener('blur', () => {
        if (!checkPhone($phone.value)) {
          $phone.closest('.form-group').classList.add('field-message');
        } else {
          $phone.closest('.form-group').classList.remove('field-message');
        }
        if (!isEmpty($phone.value)) {
          $phone.closest('.form-group').classList.remove('has-error');
        } else {
          $phone.closest('.form-group').classList.add('field-message');
        }
      });
    });
  }
}
