// //
// // Email-validation js 
// //

const forms = document.querySelector('.cus-form .submit .btn');
const inputEmail = document.querySelectorAll("[type='email']");

const isEmpty = (str) => !str.trim().length;
const validReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const checkEmail = ($val) => {
  if ($val.match(validReg)) {
    return true;
  }
};

if (forms) {
  forms.addEventListener('click', (event) => {
    if (inputEmail) {
      inputEmail.forEach(($email) => {
        if (!isEmpty($email.value)) {
          $email.closest('.form-group').classList.remove('has-error');
        }
        if (!checkEmail($email.value)) {
          $email.closest('.form-group').classList.add('has-error');
          // $email.closest('.form-group').classList.add('field-message');
        } else {
          $email.closest('.form-group').classList.remove('has-error');
          // $email.closest('.form-group').classList.remove('field-message');
        }
      });
    }
  });

  if (inputEmail) {
    inputEmail.forEach(($email) => {
      $email.addEventListener('blur', () => {
        if (!checkEmail($email.value)) {
          $email.closest('.form-group').classList.add('has-error');
          $email.closest('.form-group').classList.add('field-message');
        } else {
          $email.closest('.form-group').classList.remove('has-error');
          $email.closest('.form-group').classList.remove('field-message');
        }
        if (!isEmpty($email.value)) {
          $email.closest('.form-group').classList.remove('has-error');
        } else {
          $email.closest('.form-group').classList.remove('field-message');
        }
      });
    });
  }
}
