//
// footer JS
//
export default class FooterMenu {
  constructor($ele) {
    this.$body = document.body;
    this.$pageFooter = $ele;
  }
}

document.querySelectorAll('.page-footer').forEach(($el) => new FooterMenu($el));

document.addEventListener('click', (event) => {
  if (event.target.href && event.target.className.includes('lang-menu-item')) {
    const url = event.target.href;
    fetch(url, { cache: 'force-cache' })
      .then((response) => response.text())
      .then((html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const mainClass = doc.querySelector('.page-footer').className;
        const mainFooter = doc.querySelector('.page-footer').innerHTML;
        setTimeout(() => {
          document.querySelector('.page-footer').className = mainClass;
          document.querySelector('.page-footer').innerHTML = mainFooter;
        }, 100);
      })
      .catch((err) => {
        console.warn(err, 'this.options.errorMsg, err');
      });
  }
});
